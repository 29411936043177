import ReactHtmlParser from "react-html-parser";

import { useFetch } from "../hooks/useFetch";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import { replace_string, server_django } from "../util";
import Spinner from "../UI/Spinner";

function Message({ messageFrom, faculty, department, textStyle, header }) {
  const { data, loading, error } = useFetch("employee/message");

  if (loading) return <Spinner />;
  if (error) return <MessageBox>{error}</MessageBox>;

  let filterCondition = (obj) => {
    return obj.employee?.designation?.designation === messageFrom;
  };

  if (faculty) {
    filterCondition = (obj) => {
      const roleCondition = obj.employee?.role?.some(
        (item) => item.role === messageFrom
      );
      const facultyCondition = obj.faculty?.collegecode === faculty; // Adjust based on your actual condition

      // Combine the conditions based on your requirements (e.g., both conditions should be true)
      return roleCondition && facultyCondition;
    };
  }

  if (department) {
    filterCondition = (obj) => {
      const roleCondition = obj.employee?.role?.some(
        (item) => item.role === messageFrom
      );

      const facultyCondition = obj.employee?.department?.some(
        (item) => item.depid === department
      ); // Adjust based on your actual condition

      // Combine the conditions based on your requirements (e.g., both conditions should be true)
      return roleCondition && facultyCondition;
    };
  }

  const messageObj = data?.find(filterCondition);

  return messageObj ? (
    <>
      {!header && (
        <p
          className={`primaryHeading ${
            textStyle ? textStyle + " heading2" : "heading4"
          }`}
        >
          Message from the {messageFrom}
        </p>
      )}
      {textStyle ? <hr /> : null}

      <div className={`messageContent  ${textStyle ? textStyle : ""}`}>
        <img
          className="img-fluid messagePic py-2"
          src={messageObj.employee.staff_picture.replace(
            replace_string,
            server_django
          )}
          alt={messageObj.employee.staff_name}
        />

        <p className="allText">
          {ReactHtmlParser(messageObj.message.replace(/\r\n/g, "<br />"))}
        </p>
      </div>

      <p className="heading6 pt-4">{messageObj.employee.staff_name}</p>
      <p>
        {messageObj.employee.role
          .filter((item) => item.role === messageFrom)
          .map((item) => item.role)}
      </p>
    </>
  ) : (
    <p className="allText">There is no message</p>
  );
}

export default Message;

export function MessageContent({ messageObj }) {
  return;
}
