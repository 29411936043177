import React from "react";
import "./App.css";
import "./css/dropdown.min.css";
import "./css/transition.min.css";
import Navigation from "./component/Navigation";
import MyRoute from "./component/MyRoute";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dotenv from "dotenv";

function App() {
  dotenv.config();

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 3600000 } },
  });
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <ScrollToTop />
          <Navigation />
          <MyRoute />
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
